import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { ApplicationForm } from "./applicationForm";
import "./App.css";
import "@mantine/core/styles.css";

function App() {
  return (
    <Router>
      {" "}
      {/* Add Router here */}
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <div
          style={{
            backgroundColor: "#fffdea",
            minHeight: "100vh",
            padding: "20px",
          }}
        >
          <ApplicationForm />
        </div>
      </MantineProvider>
    </Router>
  );
}

export default App;
