import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Accordion,
  Button,
  Checkbox,
  Container,
  Image,
  Paper,
  Select,
  TextInput,
  Title,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import logo from "./logo.png";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "@mantine/dates/styles.css";
import { getCodeList } from "country-list";
import { DiscountCheck } from "tabler-icons-react";
import axios from "axios";

dayjs.extend(customParseFormat);

export function ApplicationForm() {
  const [isSending, setIsSending] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [discounts, setDiscounts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // State to track form submission
  const countryOptions = Object.values(getCodeList())
    .sort()
    .map((name) => ({
      value: name,
      label: name,
    }));
  const location = useLocation();

  const validateSelectedDates = () => {
    return selectedDates.length >= 1;
  };

  const schema = yup.object().shape({
    vanOption: yup.string().required("Van type is required"),
    selectedDates: yup
      .array()
      .test(
        "one-week-selected",
        "Please select at least one week",
        validateSelectedDates,
      ),
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    passportNumber: yup.string().required("Passport number is required"),
    phoneNumber: yup.string().required("Phone number is required"),
    dateOfBirth: yup.date().required("Date of birth is required"),
    specialRequirements: yup.string(), // optional field
    dietaryRequirements: yup.string(), // optional field
    allergies: yup.string(), // optional field
    medicalConditions: yup.string(), // optional field
    country: yup.string().required("Country is required"),
    emergencyContactName: yup
      .string()
      .required("Emergency contact name is required"),
    emergencyContactEmail: yup
      .string()
      .email("Invalid email")
      .required("Emergency contact email is required"),
    emergencyContactNumber: yup
      .string()
      .required("Emergency contact number is required"),
    emergencyContactRelationship: yup
      .string()
      .required("Relationship is required"),
    termsAccept: yup
      .boolean()
      .nullable()
      .required("You must accept the terms and conditions")
      .oneOf([true], "You must accept the terms and conditions"),
    privacyAgree: yup
      .boolean()
      .nullable()
      .required("You must agree to the privacy policy")
      .oneOf([true], "You must agree to the privacy policy"),
    driverLicenseNumber: yup
      .string()
      .required("Driver license number is required"),
    issuingCountryDriverLicense: yup
      .string()
      .required("Issuing country of driver license is required"),
    dateOfIssueDriverLicense: yup
      .date()
      .required("Date of issue for driver license is required"),
    streetAndHouseNumber: yup
      .string()
      .required("Street and house number are required"),

    postalCode: yup.string().required("Postal code is required"),
    city: yup.string().required("City is required"),
    issuingCountryOfPassport: yup
      .string()
      .required("Issuing country of passport is required"),
  });
  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dateOptions = [
    {
      label: "10th April - 17th April - United Kingdom (Devon/Cornwall)",
      value: "uk-april-10",
    },
    {
      label: "18th April - 25th April - Spain (north)",
      value: "spain-april-18",
    },
    {
      label: "25th April - 2nd May - Portugal (north)",
      value: "portugal-april-25",
    },
    //{ label: "2nd May - 9th May - Portugal (south)", value: "portugal-may-2" },
    //{ label: "9th May - 16th May - Spain (south)", value: "spain-may-9" },
  ];

  const vanOptions = [
    { value: "own-van", label: "I have my own van - €333 per week" },
    { value: "need-van", label: "I need a van supplying - €1,111 per week" },
    { value: "join-van", label: "I am joining another van - €111 per week" },
  ];

  const handleDateChange = (value, checked) => {
    setSelectedDates(
      checked
        ? [...selectedDates, value]
        : selectedDates.filter((date) => date !== value),
    );
  };

  const vanOption = watch("vanOption");

  useEffect(() => {
    // Calculate Subtotal Based on vanOption and Selected Dates
    let basePrice = 333; // Default base price for own van
    if (vanOption === "join-van") {
      basePrice = 111; // Price when joining another van
    } else if (vanOption === "need-van") {
      basePrice = 1111; // Price for needing a van
    }
    const currentSubtotal = selectedDates.length * basePrice;
    setSubtotal(currentSubtotal);

    // Initialize Discount Details
    let discountDetails = [];
    let totalDiscountAmount = 0;

    // Apply 33.33% Discount for Selecting All 5 Dates
    if (selectedDates.length === 5) {
      const discountAmount = currentSubtotal * 0.3333; // 33.33% discount
      totalDiscountAmount += discountAmount;
      discountDetails.push({
        reason: "33.33% off for all 5 dates",
        amount: discountAmount,
      });
    }

    // Check for Additional Discount Code
    const discountCode = new URLSearchParams(location.search).get("discount");
    if (btoa(discountCode) === "V0lDSzEw") {
      const friendDiscount = currentSubtotal * 0.1; // Additional 10% discount
      totalDiscountAmount += friendDiscount;
      discountDetails.push({
        reason: "10% off - Friend of Nick",
        amount: friendDiscount,
      });
    }

    setDiscounts(discountDetails);

    // Calculate and Set Total Price After Discounts
    const finalTotalPrice = currentSubtotal - totalDiscountAmount;
    setTotalPrice(finalTotalPrice);

    // Clear selectedDates error if any date is selected
    if (selectedDates.length > 0) {
      clearErrors("selectedDates");
    }
  }, [selectedDates, vanOption, location.search]); // Added location.search to dependencies to re-calculate when URL parameters change

  const onSubmit = handleSubmit(async (data) => {
    setIsSending(true);

    const formData = {
      data,
      selectedDates,
      totalPrice,
      deposit: (totalPrice * 0.3333).toFixed(2),
    };

    try {
      const response = await axios.post("/api/submitForm", formData);
      setIsFormSubmitted(true);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <>
      <Container padding="lg" align="center">
        <Image src={logo} alt="Road Retreats Logo" w={200} align="center" />
      </Container>
      <Container align="left" maw={600} mt="lg">
        <Paper withBorder shadow="md" p="lg">
          {isFormSubmitted ? (
            <div>
              <Title order={2}>You're nearly there!</Title>
              <p>
                <strong>33.33% DEPOSIT DUE TODAY:</strong> €
                {(totalPrice * 0.3333).toFixed(2)}
              </p>
              <p>
                <strong>IBAN:</strong> GB70 REVO 0099 7065 1984 33
              </p>
              <p>
                <strong>BIC / SWIFT code:</strong> REVOGB21
              </p>
              <p>
                <strong>Beneficiary:</strong> Nicholas Watson
              </p>
              <p>
                Please make payment within 5 business days or your booking will
                be cancelled.
              </p>
            </div>
          ) : (
            <div>
              <Title order={2}>Application Form</Title>
              <form onSubmit={onSubmit}>
                <Controller
                  control={control}
                  name="vanOption"
                  render={({ field }) => (
                    <Select
                      label="Van Type"
                      placeholder="Select your van"
                      mt="lg"
                      withAsterisk
                      error={errors.vanOption?.message}
                      data={vanOptions}
                      {...field}
                    />
                  )}
                />
                {dateOptions.map((option) => (
                  <Checkbox
                    key={option.value} // Assign a unique key based on the value or another unique identifier
                    label={option.label}
                    mt="lg"
                    onChange={(event) =>
                      handleDateChange(
                        option.value,
                        event.currentTarget.checked,
                      )
                    }
                    checked={selectedDates.includes(option.value)}
                  />
                ))}

                {errors.selectedDates && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {errors.selectedDates.message}
                  </div>
                )}
                <Container mt="lg">
                  <div>Subtotal: €{subtotal.toFixed(2)}</div>
                  {discounts.map((discount, index) => (
                    <div
                      key={index}
                      style={{
                        color: discount.amount > 0 ? "green" : "inherit",
                      }}
                    >
                      <div
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {discount.amount > 0 && (
                          <DiscountCheck
                            color="green"
                            size={20}
                            style={{ marginRight: "4px" }}
                          />
                        )}
                        <span>
                          Discount ({discount.reason}): -€
                          {discount.amount.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  ))}

                  <div>Total Price: €{totalPrice.toFixed(2)}</div>
                  <div>
                    <strong>
                      33.33% DEPOSIT DUE TODAY: €
                      {(totalPrice * 0.3333).toFixed(2)}
                    </strong>
                  </div>
                </Container>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextInput
                      label="Name"
                      mt="lg"
                      withAsterisk
                      error={errors.name?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextInput
                      label="Email"
                      mt="lg"
                      withAsterisk
                      error={errors.email?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="passportNumber"
                  render={({ field }) => (
                    <TextInput
                      label="Passport Number"
                      mt="lg"
                      withAsterisk
                      error={errors.passportNumber?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="issuingCountryOfPassport"
                  render={({ field }) => (
                    <Select
                      label="Issuing Country of Passport"
                      mt="lg"
                      withAsterisk
                      placeholder="Select the issuing country of your passport"
                      data={countryOptions} // Assuming you have a list of countries
                      searchable
                      error={errors.issuingCountryOfPassport?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <TextInput
                      label="Phone Number"
                      mt="lg"
                      withAsterisk
                      error={errors.phoneNumber?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ field }) => (
                    <DateInput
                      label="Date of Birth"
                      placeholder="Select date"
                      withAsterisk
                      mt="lg"
                      error={errors.dateOfBirth?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="streetAndHouseNumber"
                  render={({ field }) => (
                    <TextInput
                      label="Street and House Number"
                      mt="lg"
                      withAsterisk
                      error={errors.streetAndHouseNumber?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="postalCode"
                  render={({ field }) => (
                    <TextInput
                      label="Postal Code"
                      mt="lg"
                      withAsterisk
                      error={errors.postalCode?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <TextInput
                      label="City"
                      mt="lg"
                      withAsterisk
                      error={errors.city?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="country"
                  render={({ field }) => (
                    <Select
                      label="Country"
                      mt="lg"
                      withAsterisk
                      placeholder="Select your country"
                      data={countryOptions}
                      searchable
                      error={errors.country?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="dietaryRequirements"
                  render={({ field }) => (
                    <TextInput
                      label="Dietary Requirements"
                      mt="lg"
                      error={errors.dietaryRequirements?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="allergies"
                  render={({ field }) => (
                    <TextInput
                      label="Allergies"
                      mt="lg"
                      error={errors.allergies?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="medicalConditions"
                  render={({ field }) => (
                    <TextInput
                      label="Medical Conditions"
                      mt="lg"
                      error={errors.medicalConditions?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="specialRequirements"
                  render={({ field }) => (
                    <TextInput
                      label="Other Special Requirements or Preferences"
                      mt="lg"
                      error={errors.specialRequirements?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="emergencyContactName"
                  render={({ field }) => (
                    <TextInput
                      label="Emergency Contact Name"
                      mt="lg"
                      withAsterisk
                      error={errors.emergencyContactName?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="emergencyContactEmail"
                  render={({ field }) => (
                    <TextInput
                      label="Emergency Contact Email"
                      mt="lg"
                      withAsterisk
                      error={errors.emergencyContactEmail?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="emergencyContactNumber"
                  render={({ field }) => (
                    <TextInput
                      label="Emergency Contact Number"
                      mt="lg"
                      withAsterisk
                      error={errors.emergencyContactNumber?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="emergencyContactRelationship"
                  render={({ field }) => (
                    <TextInput
                      label="Emergency Contact Relationship"
                      mt="lg"
                      withAsterisk
                      error={errors.emergencyContactRelationship?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="driverLicenseNumber"
                  render={({ field }) => (
                    <TextInput
                      label="Driver License Number"
                      withAsterisk
                      mt="lg"
                      error={errors.driverLicenseNumber?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="dateOfIssueDriverLicense"
                  render={({ field }) => (
                    <DateInput
                      label="Date of Issue (Driver License)"
                      placeholder="Select date"
                      withAsterisk
                      mt="lg"
                      error={errors.dateOfIssueDriverLicense?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="issuingCountryDriverLicense"
                  render={({ field }) => (
                    <Select
                      label="Issuing Country (Driver License)"
                      mt="lg"
                      placeholder="Select the issuing country of your driver license"
                      withAsterisk
                      data={countryOptions}
                      searchable
                      error={errors.issuingCountryDriverLicense?.message}
                      {...field}
                    />
                  )}
                />

                <Accordion variant="separated" mt="lg">
                  <Accordion.Item value="terms">
                    <Accordion.Control>Terms and Conditions</Accordion.Control>
                    <Accordion.Panel>
                      <ol>
                        <li>
                          <strong>Booking and Payment:</strong>
                        </li>
                        <ul>
                          <li>
                            The total cost of the retreat, along with the
                            deposit amount and due date, is presented within
                            this booking form.
                          </li>
                          <li>Payment is accepted via bank transfer only.</li>
                        </ul>

                        <li>
                          <strong>Cancellation and Refund Policy:</strong>
                        </li>
                        <ul>
                          <li>
                            Cancellations made before January 1, 2024, will
                            receive a 100% refund.
                          </li>
                          <li>
                            Cancellations made before March 1, 2024, will
                            receive a 50% refund.
                          </li>
                          <li>
                            Cancellations made after March 1, 2024, will not be
                            eligible for a refund.
                          </li>
                        </ul>

                        <li>
                          <strong>
                            Van Rental Agreement (if not using own van):
                          </strong>
                        </li>
                        <ul>
                          <li>
                            A separate van rental agreement will be provided to
                            customers, including terms and conditions with the
                            rental provider.
                          </li>
                          <li>
                            Customers are required to contract directly with the
                            rental provider, with our facilitation.
                          </li>
                        </ul>

                        <li>
                          <strong>Responsibilities and Liability:</strong>
                        </li>
                        <ul>
                          <li>
                            Participants must act responsibly, respectfully, and
                            sensibly toward others during the retreat.
                            Misconduct may result in removal from the retreat
                            with no refund.
                          </li>
                          <li>
                            Our liability is limited to the total amount paid
                            for the booking.
                          </li>
                        </ul>

                        <li>
                          <strong>
                            Travel Insurance and Medical Considerations:
                          </strong>
                        </li>
                        <ul>
                          <li>
                            Participants must promptly inform us of any medical,
                            dietary, or other special requirements or conditions
                            that need our attention.
                          </li>
                          <li>
                            While we will make every effort to accommodate
                            special requirements, we cannot guarantee or be held
                            liable for fulfilling all requests.
                          </li>
                        </ul>

                        <li>
                          <strong>Flexibility of Activities:</strong>
                        </li>
                        <ul>
                          <li>
                            We are committed to accommodating all participants
                            equally and fairly. We utilize Artificial
                            Intelligence to dynamically adjust itineraries for
                            the best experience.
                          </li>
                        </ul>

                        <li>
                          <strong>Limited Availability:</strong>
                        </li>
                        <ul>
                          <li>
                            The number of attending vans is strictly limited to
                            7 to ensure a personalized and exclusive experience.
                          </li>
                        </ul>

                        <li>
                          <strong>Liability Limitations:</strong>
                        </li>
                        <ul>
                          <li>
                            Participants agree to hold us harmless from any
                            claims, damages, or liabilities arising from their
                            participation in the retreat.
                          </li>
                        </ul>
                        <li>
                          <strong>Amendment of Terms and Conditions:</strong>
                        </li>
                        <ul>
                          <li>
                            We reserve the right to amend or update these Terms
                            and Conditions as necessary. Any changes will be
                            effective immediately upon posting on this booking
                            form.
                          </li>
                          <li>
                            It is the responsibility of participants to review
                            these Terms and Conditions regularly to ensure their
                            continued understanding and compliance.
                          </li>
                        </ul>
                      </ol>
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value="privacy">
                    <Accordion.Control>Privacy Policy</Accordion.Control>
                    <Accordion.Panel>
                      <p>
                        We are committed to protecting your privacy and ensuring
                        the security of your personal information. This Privacy
                        Policy outlines how we collect, use, and protect your
                        data:
                      </p>

                      <ol>
                        <li>
                          <strong>Data Collection:</strong>
                        </li>
                        <ul>
                          <li>
                            We collect personal information, including names,
                            contact details, and emergency contact information,
                            for the purpose of retreat planning and
                            communication.
                          </li>
                        </ul>

                        <li>
                          <strong>Data Usage:</strong>
                        </li>
                        <ul>
                          <li>
                            Customer data will be used for internal purposes
                            only and may be shared with van rental partners or
                            activity providers for the sole purpose of renting
                            vans to the customer or facilitating their
                            participation in activities.
                          </li>
                          <li>
                            Your data will never be shared with third parties
                            for marketing purposes.
                          </li>
                        </ul>

                        <li>
                          <strong>Communication:</strong>
                        </li>
                        <ul>
                          <li>
                            We may contact participants about future retreats
                            and related offerings.
                          </li>
                        </ul>

                        <li>
                          <strong>Data Security:</strong>
                        </li>
                        <ul>
                          <li>
                            We implement industry-standard security measures to
                            protect customer data.
                          </li>
                        </ul>

                        <li>
                          <strong>Data Retention:</strong>
                        </li>
                        <ul>
                          <li>
                            Customer data will be retained for as long as
                            necessary for the purposes stated in this policy.
                          </li>
                        </ul>

                        <li>
                          <strong>Data Access and Correction:</strong>
                        </li>
                        <ul>
                          <li>
                            Participants have the right to access and correct
                            their personal information.
                          </li>
                        </ul>

                        <li>
                          <strong>Future Contact:</strong>
                        </li>
                        <ul>
                          <li>
                            We reserve the right to reach out to participants
                            about future retreats and offerings.
                          </li>
                        </ul>

                        <li>
                          <strong>Amendment of Privacy Policy:</strong>
                        </li>
                        <ul>
                          <li>
                            We reserve the right to amend or update this Privacy
                            Policy as necessary. Any changes will be effective
                            immediately upon posting on this booking form.
                          </li>
                          <li>
                            It is the responsibility of participants to review
                            this Privacy Policy regularly to ensure their
                            continued understanding and consent.
                          </li>
                        </ul>
                      </ol>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>

                <Controller
                  control={control}
                  name="termsAccept"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      label="I accept the Terms and Conditions"
                      mt="lg"
                      className="req"
                      error={errors.termsAccept?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="privacyAgree"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      label="I agree to the Privacy Policy"
                      mt="lg"
                      className="req"
                      error={errors.privacyAgree?.message}
                      {...field}
                    />
                  )}
                />

                <Button
                  fullWidth
                  mt="lg"
                  color="teal"
                  type="submit"
                  loading={isSending}
                >
                  Submit
                </Button>
              </form>
            </div>
          )}
        </Paper>
      </Container>
    </>
  );
}

export default ApplicationForm;
